import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'
import 'moment/locale/fr'
import { language } from '../utils'
import { applyPromo, closeInvoice, chargeInvoice, findEvent, getProducts, refreshInvoice, updateEvent, cancelEvent } from '../api'
import { loadStripe } from '@stripe/stripe-js'
import I18n from '../components/UI/I18n'
import ErrorPage from '../components/UI/ErrorPage'
import Loader from '../components/UI/Loader'
import Header from '../components/UI/Header'
import Title2 from '../components/UI/Title2'
import HelpCenter from '../components/HelpCenter'
import Options from '../components/Options'
import Summary from '../components/Summary'
import Delivery from '../components/Delivery'
import Customization from '../components/Customization'
import { FiArrowDown } from 'react-icons/fi'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import Headers from '../components/UI/Headers'
import Button from '../components/UI/Button'


moment.locale('en')

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const innerWidth = () => (typeof window !== `undefined`) ? window.innerWidth : 1024
const match = (location().pathname.match(/event\/(\d+)_(.+)/) || [])
const eventId = match[1]
const token = match[2]

const payTrigger = {}

function debounce(fn, ms) {
	let timer
	return () => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			timer = null
			fn.apply(this, arguments)
		}, ms)
	}
}

export default function Event() {
  const { stripePublic } = useStaticQuery(graphql`
    {
      strapi {
        locales {
          stripePublic
          language
        }
      }
    }
  `).strapi.locales.find(l => l.language === language())
  const [stripePromise, setStripePromise] = useState()
  const [width, setWidth] = useState(innerWidth())
  const [summary, setSummary] = useState(false)
  const [products, setProducts] = useState([])
  const [event, setEvent] = useState(null)
  const options = products && products.filter(p => p.upsale)
  const shippings = products && products.filter(p => p.shipping)
  const [load, setLoad] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [cancelPanel, setCancelPanel] = useState(false)
  const [unsubscribe, setUnsubscribe] = useState(false)

  useEffect(() => {
    const handleResize = debounce(() => setWidth(innerWidth()), 500)
    window.addEventListener('resize', handleResize)

    return () => {
        window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    setStripePromise(loadStripe(stripePublic))
  }, [])

  useEffect(() => {
    setCancelPanel(location().search.includes('unsubscribe=true'))
  }, [location])

  useEffect(() => {
    if (event && event.stripeCache) return
    if (event && event.error) return
    if (products.length === 0)
      getProducts().then(p => setProducts(p))
    findEvent(eventId, token).then(e => setEvent(e))
  }, [event])
  if (event && event.error) return <ErrorPage message={event.message} />
  if (event === null || event.stripeCache === null) return <Loader />

  const upcoming = event && event.stripeCache && event.stripeCache.upcoming
  const invoices = event.stripeCache && event.stripeCache.invoices || []
  const current = event.stripeCache && (upcoming || invoices.find(i => i.status === 'draft') || invoices.find(i => i.status === 'open'))
  const paidInvoice = event.stripeCache && invoices.find(i => i.status === 'paid')

  const loading = async () => {
    if (current) current.loading = true
    setEvent({ ...event })
  }
  const unloading = async () => {
    if (current) current.loading = false
    setEvent({ ...event })
  }
  const tryPromo = async code => {
    try {
      loading()
      return setEvent(await applyPromo(event, code))
    } catch (error) {
      unloading()
      return Promise.reject(error)
    }
  }
  const finalize = async () => {
    return closeInvoice(event)
  }
  const advance = async () => {
    return chargeInvoice(event, 15000)
  }
  const onCreaSaved = async crea => {
    return setEvent({ ...event, customization: crea })
  }
  const succeeded = async () => {
    loading()
    return setEvent(await refreshInvoice(event))
  }
  const addProduct = async product => {
    product.active = true
    loading()
    return setEvent(await updateEvent(event, { products: [...event.products.map(p => p.id), product.id] }))
  }
  const removeProduct = async product => {
    product.active = false
    loading()
    return setEvent(await updateEvent(event, { products: event.products.filter(p => p.id !== product.id).map(p => p.id) }))
  }
  const setPickup = async pickup => {
    return setEvent(await updateEvent(event, { pickup }))
  }
  const setAddress = async address => {
    return setEvent(await updateEvent(event, { address }))
  }
  const setCustomerInfo = async (firstname, email, phone) => {
    return setEvent(await updateEvent(event, { firstname: firstname, email: email, phone: phone }))
  }
  const deleteEvent = async event => {
    console.log('event inside cancelEvent', event)
    const response = await cancelEvent(event)
    console.log('cancelEvent response', response)
    setUnsubscribe(true)
  }
  const props = { width, shippings, options, event, current, paidInvoice, payTrigger, upcoming, stripePromise, load, setSummary, setPickup, setAddress, setLoad, onCreaSaved, setCustomerInfo, addProduct, removeProduct, tryPromo, finalize, advance, succeeded }

  if (cancelPanel) {
    return (
      <div className="w-screen h-screen overflow-hidden relative flex flex-col items-center snap-none">
        <div className="absolute transform rotate-45 -z-10 opacity-50 overflow-hidden" style={{ backgroundColor: '#FADAC5', width: width >= 1024 ? '40rem' : '30rem', height: '75rem', bottom: width >= 1024 ? '10rem' : '35rem', left: '-10rem', borderRadius: '5rem' }} />
        <div className="absolute transform -rotate-45 -z-10 opacity-50 overflow-hidden" style={{ backgroundColor: '#FADAC5', width: width >= 1024 ? '40rem' : '30rem', height: '75rem', top: width >= 1024 ? '50rem' : '35rem', right: '-20rem', borderRadius: '5rem' }} />
        <img src="/icons/Wizito.png" alt="Wizito" className="w-40 mb-3 mt-20" />
          <div className="flex flex-col items-start mt-12">
          {!unsubscribe ? (
            <>
              <div className="flex justify-start items-center w-full text-center px-6">
                <p className="text-4xl mr-3"><I18n>app-redesign.dashboard.greeting</I18n> {event.firstname}</p>
                <div className="waving text-3xl" data-hover="👋">👋</div>
              </div>
              <p className="text-3xl mt-8 px-6"><I18n>app-redesign.subscription.warningMessage</I18n> {moment(event.date).format('Do of MMMM')}?</p>
            </>
          ) : (
              <p className="text-3xl mt-8 lg:mt-20 px-6"><I18n>app-redesign.subscription.unsubscribedMessagePart1</I18n> {moment(event.date).format('Do of MMMM')} <I18n>app-redesign.subscription.unsubscribedMessagePart2</I18n></p>
          )
        }
        </div>
        {!unsubscribe && (
          <div className="flex justify-center items-center mt-24">
            <Button
              variant="default"
              className="mx-4 lg:mx-8 w-40 sm:w-56 sm:h-16 font-semibold uppercase"
              style={{color: 'black'}} 
              onClick={() => deleteEvent(event)}>
              <I18n>app-redesign.subscription.unsubscribeBtn</I18n>
            </Button>
            <Button
              variant="yellow"
              className="mx-4 lg:mx-8 w-40 sm:w-56 sm:h-16" 
              href={location().pathname + location().search.replace(/&?unsubscribe=true&?/, '')}>
              <I18n>app-redesign.subscription.discardBtn</I18n>
            </Button>
          </div>
        )}
      </div>
    )
  } else {
  return (
    <div className="relative pb-10">
      <div className="absolute top-0 bottom-0 w-screen overflow-hidden">
        <div className="absolute transform rotate-45 -z-10 opacity-50" style={{ backgroundColor: '#FADAC5', width: '40rem', height: '75rem', top: width >= 1024 ? '-5rem' : '-20rem', right: width >= 1024 ? '-20rem': '-35rem', borderRadius: '5rem' }} />
        <div className="absolute transform rotate-45 -z-10 opacity-50" style={{ backgroundColor: '#FADAC5', width: width >= 1024 ? '40rem' : '30rem', height: '75rem', bottom: width >= 1024 ? '-50rem' : '35rem', left: '-10rem', borderRadius: '5rem' }} />
        <div className="backgroundShape absolute -z-10 transform rotate-45 lg:rotate-0" style={{ top: width >= 1024 ? '45rem' : '55rem', left: width >= 1024 ? '-15rem' : '-25rem' }} />
        <div className={`${width >= 1024 ? 'backgroundShape' : 'backgroundShapeMobile'} absolute -z-10`} style={{ bottom: width >= 1024 ? '20rem' : '45rem' , right: '-10rem' }} />
      </div>
      <Header {...props} />
      <Headers />
      <div className="content h-full mx-auto relative w-auto lg:max-w-250" style={{ paddingTop: 130}}>
        <div className="flex lg:hidden flex-col justify-center items-center w-full h-full">
          {summary && <Summary {...props} />}
          <div 
            className="flex justify-between items-start w-100 px-6 mt-20 relative">
            <p className="text-3xl absolute bottom-0">
              <I18n>app-redesign.dashboard.greeting</I18n>
              <span className="waving text-3xl inline-block ml-5" data-hover="👋">👋</span>
            </p>
            <div className="bg-white rounded-xl px-6 py-2 shadow-lg cursor-pointer absolute top-0 right-3">
              <p className="text-center relative z-10 text-xl" onClick={() => setSummary(true)}>
                Summary<span className="absolute w-10/12 h-4/10 rounded-xl bottom-0 left-1/2 transform -translate-x-1/2 -z-10 bg-yellow"></span>
              </p>
            </div>
          </div>
          <div className="flex justify-between items-end w-100 py-0 my-0 px-6 sm:px-0 mb-8">
            <p className="capitalize text-3xl text-center">{event.firstname}</p>
          </div>
          <img src="/icons/Wizito.png" alt="Wizito" className="w-24 mb-8" />
          <p className="text-2xl text-center font-semibold w-8/12 relative">
            <I18n>app-redesign.dashboard.title</I18n>
            <span className="absolute w-8/10 h-5/10 pb-6 left-1/10 rounded-xl top-5 -z-10 bg-yellow"></span>
          </p>
          <FiArrowDown className="arrow text-4xl my-2" />
          <div className="flex justify-between w-full mt-2">
            <div className="bg-pink text-white w-60 mr-2 rounded-xl flex flex-col justify-center items-center shadow-md px-6 py-5 whitespace-no-wrap">
              <p className="text-2xl">🗓️</p>
              <p className="text-2xl font-medium"><I18n>app-redesign.dashboard.calendarTitle</I18n></p>
              <p className="text-xl">{moment(event.date).format('DD/MM/YYYY')}</p>
            </div>
            <div className="rounded-xl shadow-lg w-60 ml-2 bg-cover" style={{ backgroundImage: "url(/image1.png)" }} />
          </div>
        </div> 
        <div className="hidden lg:flex flex-col justify-center items-center w-full h-full">
          <div className="flex justify-end items-end h-full w-full static">
            <div className="flex flex-col justify-between items-start w-8/12 h-160">
              <div className="w-full mb-8">
                <img src="/icons/Wizito.png" alt="Wizito" className="w-24 mb-3" />
                <div className="flex justify-start items-center w-full">
                  <p className="text-3xl text-center mr-3"><I18n>app-redesign.dashboard.greeting</I18n> {event.firstname}</p>
                  <div className="waving text-3xl" data-hover="👋">👋</div>
                </div>
                <Title2 variant="extra-large" underline="w-3/12 h-5/10 left-1/10">
                  <I18n>app-redesign.dashboard.title</I18n>
                </Title2>
              </div>
              <div className="flex justify-between items-start h-7/10 w-full">
                <div className="bg-pink text-white rounded-xl flex flex-col justify-center items-center shadow-md px-6 py-5 whitespace-no-wrap">
                  <p className="text-2xl">🗓️</p>
                  <p className="text-3xl font-medium"><I18n>app-redesign.dashboard.calendarTitle</I18n></p>
                  <p className="text-xl">{moment(event.date).format('DD/MM/YYYY')}</p>
                </div>
                <div 
                  className="rounded-xl shadow-md h-full w-full mx-12 bg-cover"
                  style={{ backgroundImage: 'url(/image1.png)' }} />
              </div>
            </div>
            <Summary {...props} />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-start w-full mt-12">
          <div className="rounded-xl hidden lg:inline-block w-full shadow-md mr-6 bg-cover" style={{ backgroundImage: 'url(/image2.png)', height: '30rem' }} />
          <Delivery 
            shippings={shippings} event={event} upcoming={upcoming} products={products} 
            addProduct={addProduct} removeProduct={removeProduct} setPickup={setPickup} setAddress={setAddress} />
          <Customization customization={event.customization} saved={onCreaSaved} />
        </div>
        <Options options={options} event={event} addProduct={addProduct} removeProduct={removeProduct} current={current} setLoad={setLoad} width={width} />
        <div className="mt-20">
          <Title2 variant="medium" underline={`h-4/10 left-8 ${width < 1024 ? 'w-3/12' : 'w-1/12'}`}>
            🕺&nbsp;<I18n>app-redesign.how.title</I18n> 
          </Title2>
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-between w-full mt-10">
          <iframe className="rounded-xl h-84 shadow-lg flex-shrink-0 max-w-full" width="560" height="315" src="https://www.youtube.com/embed/_kSyDmLdev4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
          <div className="flex flex-col justify-between items-center w-full lg:w-auto lg:mx-6">
            <a className="flex flex-col justify-center items-center text-pink text-2xl w-full h-40 my-6 lg:my-0 bg-pink-light rounded-xl shadow-md" href="https://www.youtube.com/watch?v=C_BQuIX_rDI" target="_blank">
              <div className="text-4xl">👀</div>
              <I18n>app-redesign.how.videos</I18n>
            </a>
            <HelpCenter showHelp={showHelp} setShowHelp={setShowHelp} />
          </div>
          <div className="hidden lg:block rounded-xl shadow-lg w-full h-84 bg-cover" style={{ backgroundImage: 'url(/image9.png)' }} />
        </div>
        <div className="flex flex-col lg:flex-row justify-start items-between w-full mt-6 mb-20">
          <div className="flex flex-col justify-between items-center w-full lg:w-auto">
            <a className="flex flex-col justify-center items-center text-2xl w-full h-40 bg-pink rounded-xl shadow-md" href="https://www.youtube.com/playlist?list=PLWhpNjW6sPUKL-0Ef9zs0RvDfFgX_AQzX" target="_blank">
              <Title2 className="text-2xl">❤️&nbsp;<I18n>app-redesign.how.follow</I18n></Title2>
              <div className="flex text-white">
                <a href="https://www.facebook.com/wizitophotobooth" target="_blank"><FaFacebook className="h-10 w-10 mt-4 mx-2" /></a>
                <a href="https://www.instagram.com/wizito_party/" target="_blank"><FaInstagram className="h-10 w-10 mt-4 mx-2" /></a>
              </div>
            </a>
            <a className="flex justify-center w-full my-6 lg:my-0" href="https://uk.trustpilot.com/review/www.wizito.com" target="_blank">
              <div className="flex flex-col justify-center items-center lg:items-start w-10/12 sm:w-100 lg:w-64 h-40 lg:h-36 shadow-lg px-6 text-white rounded-xl" style={{ backgroundColor: '#061740' }}>
                <p className="text-sm">Reviews 46 - Excellent</p>
                <img src="/reviews.png" alt="reviews" />
              </div>
            </a>
          </div>
          <div className="hidden lg:block rounded-xl shadow-lg w-120 h-84 bg-cover mx-6" style={{ backgroundImage: 'url(/image8.png)' }} />
          <div className="hidden lg:block rounded-xl shadow-lg w-44 h-56 bg-cover" style={{ backgroundImage: 'url(/image10.png)' }} />
        </div>
        <p className="text-base text-center mb-10 text-pink">&copy; {moment().format('YYYY')} Wizito</p>
		  </div>
    </div>
  )
  }
}
