import React from 'react'
import moment from 'moment'
import { Elements } from '@stripe/react-stripe-js'
import I18n from './I18n'
import Button from './Button'
import Price from './Price'
import PayEvent from '../PayEvent'
import { FiLoader } from 'react-icons/fi'
moment.locale('en')

const Header = ({ current, paidInvoice, payTrigger, event, stripePromise, upcoming, tryPromo, finalize, advance, succeeded, load }) => {
	return (
		<div 
			className="fixed z-100 top-0 w-screen py-3 flex flex-col lg:flex-row justify-center items-center bg-pink">
			{current && !(paidInvoice || current.pre_payment_credit_notes_amount > 0) && 
				<>
					<p className="text-white text-center lg:mr-8">
						<I18n>app-redesign.header.notPaid</I18n> {event.date ? `on ${moment(event.date).format('DD/MM/YYYY')}` : ''}
					</p>
					<div className="flex justify-center lg:justify-between w-full lg:w-auto items-center mt-2 lg:mt-0">
							{event.stripeCache &&
								<Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@100;300;400;500' }] }} >
									<PayEvent event={event} upcoming={upcoming} tryPromo={tryPromo} finalize={finalize} succeeded={succeeded} payTrigger={payTrigger} advance={advance} />
								</Elements>
							}
						<Button variant="transparent" className="mx-3 py-3 hidden md:block whitespace-no-wrap"
							onClick={() => window.location = 'mailto:sales@wizito.com'}><I18n>app-redesign.header.contact</I18n>
						</Button>
					</div>
				</>
			}
			{!current && paidInvoice &&
				<>
					<p className="text-white text-center lg:mr-8">
						<I18n>app-redesign.header.paid</I18n> {event.date ? `for ${moment(event.date).format('DD/MM/YYYY')}` : ''}
						{load && <FiLoader className="h-8 w-8 stroke-1 rotating box-border inline ml-4" />}
					</p>
				</>
			}
			{current && (paidInvoice || current.pre_payment_credit_notes_amount > 0) &&
				<>
					<p className="text-white text-center lg:mr-8">
						<I18n>app-redesign.header.partiallyPaid</I18n> &nbsp;<Price price={current.amount_remaining} loading={current.loading} />&nbsp; to pay
					</p>
					<div className="flex justify-center lg:justify-between w-full lg:w-auto items-center mt-2 lg:mt-0">
						{event.stripeCache &&
							<Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@100;300;400;500' }] }} >
								<PayEvent event={event} upcoming={upcoming} tryPromo={tryPromo} finalize={finalize} succeeded={succeeded} payTrigger={payTrigger} advance={advance} />
							</Elements>
						}
						<Button variant="transparent" className="mx-3 py-3 hidden md:block whitespace-no-wrap"
							onClick={() => window.location = 'mailto:sales@wizito.com'}><I18n>app-redesign.header.contact</I18n>
						</Button>
					</div>
				</>
			}
		</div>
	)
}

export default Header
