import React, { useState, useEffect, useRef } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import moment from 'moment'
import I18n from './UI/I18n'
import Button from './UI/Button'
import Title2 from '../components/UI/Title2'
import PayEvent from '../components/PayEvent'
import { FiEdit, FiX } from "react-icons/fi"

const inputStyle = 'border-none focus:outline-none'
let opts = []

const Summary = ({ event, options, setSummary, setCustomerInfo, payTrigger, stripePromise, upcoming, tryPromo, finalize, advance, succeeded }) => {
  const [open, setOpen] = useState(false)
  const [firstname, setFirstname] = useState(event.firstname)
  const [email, setEmail] = useState(event.email)
  const [phone, setPhone] = useState(event.phone)

  const invoices = [...event.stripeCache.invoices, upcoming]
  const deliveryMethod = event.products.some(p => p.upsale && p.shipping) ? 'Delivery' : 'Pickup'

  const inputElement = useRef()

  useEffect(() => {
      if (inputElement.current)
        inputElement.current.focus()
  }, [inputElement, open])


  return (
    <div className={`z-75 flex flex-col bg-white w-full md:w-96 px-10 rounded-xl shadow-md mt-2 fixed lg:relative left-1/2 lg:left-0 top-1/2 lg:top-0 transform -translate-x-1/2 lg:translate-x-0 -translate-y-1/2 lg:translate-y-0`}>
      <FiX className="block md:hidden text-2xl cursor-pointer absolute top-6 right-6" onClick={() => setSummary(false)}/>
      <Title2 variant="medium" className="mx-auto mt-8 mb-6">
        <I18n>app-redesign.summary.title</I18n>
      </Title2>
      {!open && (
        <div className="flex justify-between items-center w-full">
          <div className="text-base flex justify-start items-center">
            <div className="w-full">
              <p className="capitalize w-full">❤️ {firstname}</p>
              <p>✉️ {email}</p>
              <p>📞 {phone}</p>
            </div>
          </div>
          <Button variant="icon" Icon={FiEdit} onClick={() => setOpen(true)} />
        </div>
      )}
      {open && (
        <>
          <div className="text-base flex justify-start items-center">
            <div className="w-full">
              <span className="capitalize flex justify-start items-center w-full">
                <p>❤️ &nbsp;</p>
                <input type="text" className={`${inputStyle} w-9/10`} ref={inputElement} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
              </span>
              <span className="flex justify-start items-center w-full">
                <p>✉️ &nbsp;</p>
                <input type="text" className={`${inputStyle} w-9/10`} value={email} onChange={(e) => setEmail(e.target.value)} />
              </span> 
              <span className="flex justify-start items-center w-full">
                <p>📞 &nbsp;</p>
                <input type="text" className={`${inputStyle} w-9/10`} value={phone} onChange={(e) => setPhone(e.target.value)} />
              </span>
            </div>
          </div>
          <Button
            variant="yellow"
            className="mx-auto" 
            onClick={() => setCustomerInfo(firstname, email, phone ).then(() => setOpen(false))}>
            <I18n>app-redesign.summary.save</I18n>
          </Button>
        </>
      )}
      <hr className="border-black opacity-10 my-5"/>
      <div className="text-base">
        <p>📸️ <I18n>app-redesign.summary.photos</I18n></p>
        <p>🗓️️ {moment(event.date).format('DD/MM/YYYY')}</p>
        <p>🚛 {deliveryMethod}</p>
      </div>
      <div className="text-base my-5">
        <p className={opts.length < 1 ? 'mb-4' : 'mb-2'}><I18n>app-redesign.summary.options</I18n> :</p>
        {options.map((option, i) => {
          const invoice = invoices.find(i => i && i.lines.data.find(l => l.price.product === option.stripeCache.product.id))
          const active = option.active === undefined ? invoice : option.active
          if (active) {
            opts.push(option)
            return <p key={option.id}>➕&nbsp;{option.name}</p>
          }
        })}
        {opts.length < 1 && <p className="text-center text-gray-500"><I18n>app-redesign.summary.noOptions</I18n></p>}
      </div>
      <div className="flex flex-col items-center">
        {event.stripeCache &&
          <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@100;300;400;500' }] }}>
            <PayEvent
              event={event} upcoming={upcoming} tryPromo={tryPromo}  
              finalize={finalize} succeeded={succeeded} payTrigger={payTrigger} advance={advance} />
          </Elements>
        }
        <p className="text-sm mt-3 mb-6 px-3 py-1 rounded bg-gray-100"><I18n>app-redesign.summary.contact</I18n> <b><I18n>app-redesign.summary.phone</I18n></b></p>
      </div>
    </div>
  )
}

export default Summary
