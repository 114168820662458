import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'
import Button from './UI/Button'
import Label from './UI/Label'
import Popup from './UI/Popup'
import { FiCheck } from 'react-icons/fi'
import moment from 'moment'

const styles = {
  default: {backgroundColor: '#F7F1ED', color: '#C29A9D'},
  selected: {backgroundColor: '#C29A9D', color: '#F7F1ED'}
}

export const AddressModal = ({ event, width, confirmAddress, close }) => {
  const [address, setAddress] = useState(event.address || {
    first_name: event.firstname,
    last_name: event.lastname,
    email: event.email,
    phone: event.phone,
    address1: '',
    address2: '',
    zip: '',
    city: 'London',
    country: 'UK',
    addInfo: '',
    deliverAt: moment(event.date),
    collectAt: moment(event.date).clone().add(1, 'd')
  })
  
  const selectAdress = (autoAddress, id) => {
    geocodeByPlaceId(id).then(result => {
      const getComponent = type =>
        result[0].address_components.find(c => c.types.find(t => t === type))?.long_name
      setAddress({
        ...address,
        autoAddress,
        address1: `${getComponent('street_number') || ''} ${getComponent('route')}`,
        zip: getComponent('postal_code'),
        city: getComponent('locality'),
        country: getComponent('country')
      })
    })
    .catch(error => console.error(error))
  }

  return (
    <Popup title="Delivery information" close={close}>
      <div className={`flex flex-wrap justify-center items-center ${width < 1024 ? 'w-full' : ''}`}>
        <input name="address" style={{ opacity: 0, position: 'absolute' }} />
        <input name="adresse" style={{ opacity: 0, position: 'absolute' }} />
        <input name="name" style={{ opacity: 0, position: 'absolute' }} />
        <Label title="Firstname">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, first_name: e.target.value })}
            type="text"
            value={address.first_name}
          />
        </Label>
        <Label title="Lastname">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, last_name: e.target.value })}
            type="text"
            value={address.last_name}
          />
        </Label>
        <Label title="Email">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, email: e.target.value })}
            type="text"
            value={address.email}
          />
        </Label>
        <Label title="Phone">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, phone: e.target.value })}
            type="text"
            value={address.phone}
          />
        </Label>
        <Label title="Address">
          <PlacesAutocomplete
            value={address.address1}
            onChange={a => setAddress({ ...address, address1: a })}
            onSelect={selectAdress}
            searchOptions={{ types: ['address'], componentRestrictions: { country: ['uk'] } }}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="relative w-full">
                <input
                  className="form-input w-full"
                  {...getInputProps()}
                  autoComplete="nope" />
                {suggestions.length > 0 &&
                  <div className="absolute bg-white shadow-lg text-base z-10 text-left w-full border border-gray-200">
                    {suggestions.map(s =>
                      <div className="px-2 py-2 hover:bg-taupe hover:text-white cursor-pointer leading-none border-b border-gray-100" {...getSuggestionItemProps(s)}>
                        {s.formattedSuggestion.mainText}
                        <br/>
                        <small>{s.formattedSuggestion.secondaryText}</small>
                      </div>
                    )}
                  </div>
                }
              </div>
            )}
          </PlacesAutocomplete>
        </Label>
        <Label title="More details">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, address2: e.target.value })}
            type="text"
            value={address.address2}
          />
        </Label>
        <Label title="Post code" className="w-1/3">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, zip: e.target.value })}
            type="text"
            value={address.zip}
          />
        </Label>
        <Label title="City" className="w-1/3">
          <input
            className="form-input w-full"
            onChange={e => setAddress({ ...address, city: e.target.value })}
            type="text"
            value={address.city}
          />
        </Label>
        {/* <Label title="Country" className="w-1/3">
          <input
            className="form-input w-full"
            type="text"
            disabled
            value={'UK'}/>
        </Label> */}
        <Label title="Delivery date">
          <input
            type="date"
            className="focus:outline-none mb-4"
            value={moment(address.deliverAt).format('YYYY-MM-DD')}
            onChange={e => setAddress({...address, deliverAt: moment(e.target.value).clone().hours(moment(address.deliverAt).hours())})} />
          <div className={`flex ${width < 1024 ? 'flex-wrap' : ''}`}>
            {[9, 11, 13, 15].map(h => (
              <Button 
                key={h}
                className='m-1' 
                Icon={moment(address?.deliverAt).hours() === h ? FiCheck : undefined} 
                style={moment(address?.deliverAt).hours() === h ? styles.selected : styles.default} 
                onClick={() => setAddress({...address, deliverAt: moment(address.deliverAt).clone().hours(h)})}>
                {h}-{h + 2}h
              </Button>
            ))}
          </div>
        </Label>
        <Label title="Recovery date">
          <input 
            type="date"
            className="focus:outline-none mb-4"
            value={moment(address.collectAt).format('YYYY-MM-DD')}
            onChange={e => setAddress({...address, collectAt: moment(e.target.value).clone().hours(moment(address.deliverAt).hours())})} />
          <div className={`flex ${width < 1024 ? 'flex-wrap' : ''}`}>
            {[9, 11, 13, 15].map(h => (
              <Button 
                key={h}
                className='m-1' 
                Icon={moment(address?.collectAt).hours() === h ? FiCheck : undefined} 
                style={moment(address?.collectAt).hours() === h ? styles.selected : styles.default} 
                onClick={() => setAddress({...address, collectAt: moment(address.collectAt).clone().hours(h)})}>
                {h}-{h + 2}h
              </Button>
            ))}
          </div>
        </Label>
        <Label title="Additional information" className="w-1/3">
          <textarea
            value={address?.addInfo}
            onChange={e => setAddress({ ...address, addInfo: e.target.value })}
            className="w-full form-input h-32"
          />
        </Label>
      </div>
      <Button 
        variant="yellow" 
        className={`mt-4 ${width < 1024 ? 'mb-40' : ''}`} 
        onClick={() => confirmAddress(address).then(close)}>Confirm</Button>
    </Popup>
  )
}

export const PickupModal = ({ event, setPickup, close, width }) => {
  const [grabAt, setGrabAt] = useState(event.pickup?.grabAt ? moment(event.pickup?.grabAt) : moment(event.date).isoWeekday(-2))
  const [returnAt, setReturnAt] = useState(event.pickup ? moment(event.pickup?.returnAt) : moment(event.date).add(7, 'days'))

  const isoWeekDays = ['Friday', 'Saturday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday']
  isoWeekDays[-1] = 'Thursday'
  isoWeekDays[-2] = 'Wednesday'
  const date = moment(event.date)
  const weekdayNumber = date.weekday()
  console.log(returnAt.isoWeekday(), weekdayNumber)
  const day = isoWeekDays[weekdayNumber + 1]
  const address = 'Busworks 39 North Road, London N7 9DP'
  const threeDaysBefore = weekdayNumber == 1 ? ['Thursday', 'Friday', 'Saturday'] : ['Friday', 'Saturday', 'Monday']
  const twoDaysAfter = day === 'Friday' ? ['Saturday', 'Monday'] : ['Monday', 'Tuesday']
  return (
    <Popup title="Pickup" close={close}>
      <div className="flex justify-between flex-wrap w-full text-left">
        <div>
          Grab:
          <div className="flex">
            {grabAt && ((grabAt.isoWeekday() < weekdayNumber - 3)) &&
              <Button
                className='m-1' 
                Icon={FiCheck}
                style={styles.selected} >
                {isoWeekDays[grabAt.isoWeekday() + 1]} {grabAt.format('DD/MM')}
              </Button>
            }
            {(day === 'Monday' || day === 'Tuesday') ?
              threeDaysBefore.map(d => {
                let index = isoWeekDays.findIndex(day => day === d)
                const selected = grabAt.weekday() === moment().isoWeekday(d).weekday()
                return (
                  <Button
                    key={d}
                    className='m-1' 
                    Icon={selected ? FiCheck : undefined} 
                    style={selected ? styles.selected : styles.default} 
                    onClick={() => setGrabAt(grabAt.clone().isoWeekday(d))}>
                    {d} {index <= 1 ? date.clone().isoWeekday(d).subtract(7, 'days').format('DD/MM') : date.clone().isoWeekday(d).format('DD/MM')}
                  </Button>
                )
              }) :
              [isoWeekDays[weekdayNumber - 2], isoWeekDays[weekdayNumber - 1], isoWeekDays[weekdayNumber]].map(d => {
                const selected = grabAt.weekday() === moment().isoWeekday(d).weekday()
                return (
                  <Button
                    key={d}
                    className='m-1' 
                    Icon={selected ? FiCheck : undefined} 
                    style={selected ? styles.selected : styles.default} 
                    onClick={() => setGrabAt(grabAt.clone().isoWeekday(d))}>
                    {d} {date.clone().isoWeekday(d).format('DD/MM')}
                  </Button>
                )
              })
            }
          </div>
          <div className="flex">
            {[10, 13, 16].map(h => (
              <Button 
                key={h}
                className='m-1' 
                Icon={grabAt.hours() === h ? FiCheck : undefined} 
                style={grabAt.hours() === h ? styles.selected : styles.default} 
                onClick={() => setGrabAt(grabAt.clone().hours(h))}>
                {h}-{h + 1}h
              </Button>
            ))}
          </div>
        </div>
        <div>
          Return:
          <div className="flex">
            {(day === 'Friday' || day ===  'Saturday') ?
                twoDaysAfter.map(d => {
                  let index = isoWeekDays.findIndex(day => day === d)
                  const selected = returnAt.weekday() === moment().isoWeekday(d).weekday()
                  return (
                    <Button 
                      key={d}
                      className='m-1' 
                      Icon={selected ? FiCheck : undefined} 
                      style={selected ? styles.selected : styles.default} 
                      onClick={() => setReturnAt(returnAt.clone().isoWeekday(d))}>
                      {d} {index >= 2 ? date.clone().add(7, 'days').isoWeekday(d).format('DD/MM') : date.clone().isoWeekday(d).format('DD/MM')}
                    </Button>
                  )
                }) :
              [isoWeekDays[weekdayNumber + 2], isoWeekDays[weekdayNumber + 3]].map(d => {
                const selected = returnAt.weekday() === moment().isoWeekday(d).weekday()
                return (
                  <Button 
                    key={d}
                    className='m-1' 
                    Icon={selected ? FiCheck : undefined} 
                    style={selected ? styles.selected : styles.default} 
                    onClick={() => setReturnAt(returnAt.clone().isoWeekday(d))}>
                    {d} {date.clone().isoWeekday(d).format('DD/MM')}
                  </Button>
                )
              })}
              {returnAt && ((returnAt.isoWeekday() > weekdayNumber + 3) || returnAt.isoWeekday() < weekdayNumber) &&
                <Button
                  className='m-1' 
                  Icon={FiCheck}
                  style={styles.selected} >
                  {isoWeekDays[returnAt.isoWeekday() + 1]} {returnAt.format('DD/MM')}
                </Button>
              }
          </div>
          <div className="flex">
            {[10, 13, 16].map(h => (
              <Button 
                key={h}
                className='m-1' 
                Icon={returnAt.hours() === h ? FiCheck : undefined} 
                style={returnAt.hours() === h ? styles.selected : styles.default} 
                onClick={() => setReturnAt(returnAt.clone().hours(h))}>
                {h}-{h + 1}h
              </Button>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full h-84 mt-5 rounded overflow-hidden">
        <iframe className="w-full h-84" frameBorder="0" src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAVnzQlyDf5j9bbHjSqrXK4h8zTuFun22Q&language=en-GB&q=${address}&zoom=12`} />
      </div>
      <Button 
        variant="yellow" 
        className={`mt-2 lg:mt-4 ${width < 1024 ? 'mb-40' : ''}`} 
        onClick={() => { setPickup({ grabAt: grabAt.toISOString(), returnAt: returnAt.toISOString() }); close() }}>Confirm</Button>
    </Popup>
  )

}

const DeliveryModal = ({open, setOpen, event, width, setPickup, setAddress}) => (
  <React.Fragment>
      <div><GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyAVnzQlyDf5j9bbHjSqrXK4h8zTuFun22Q&libraries=places' }} /></div>
      {open && event.products.some(p => p.upsale && p.shipping) &&
          <AddressModal event={event} width={width} confirmAddress={setAddress} close={() => setOpen(false)} />
      }
      {open && !event.products.some(p => p.upsale && p.shipping) &&
          <PickupModal event={event} width={width} setPickup={setPickup} close={() => setOpen(false)} />
      }
  </React.Fragment>
)

export default DeliveryModal
