import React, { useEffect } from 'react'
import { FiMapPin, FiTruck } from 'react-icons/fi'

const DeliveryOption = ({ event, products, selected, shipping, addProduct, removeProduct, setSelected, setLoading }) => {
    useEffect(() => {
        event.products.some(p => p.upsale && p.shipping) ? setSelected([true, false]) : setSelected([false, true])
    }, [event])

    const handleSelection = (shipping) => {
        if (shipping.upsale) {
            (async () => {
                setSelected([true, false])
                setLoading(true)
                const deliveryOption = products.find(p => p.upsale && p.shipping)
                await addProduct(deliveryOption)
                setLoading(false)
            })()
        } else if (!shipping.upsale) {
            setSelected([false, true])
            const deliveryOption = products.find(p => p.upsale && p.shipping)
            removeProduct(deliveryOption)
        } else {
            return
        }
    }
    return (
        <div
            className={`w-5/12 mx-3 ${selected ? 'border-4 border-pink' : ''} bg-white text-base rounded-xl shadow-lg relative cursor-pointer py-6`} 
            onClick={() => handleSelection(shipping)}>
            <div
                className={`text-base py-1 px-3 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full shadow whitespace-no-wrap bg-pink text-white`}>
                {shipping.upsale ? `+£${shipping.stripeCache.unit_amount / 100}` : 'Free'}
            </div>
            {shipping.upsale ? <FiTruck className="w-10 h-10 mx-auto stroke-1" /> : <FiMapPin className="w-10 h-10 mx-auto stroke-1" />}
            <p>{shipping.stripeCache.product.name}</p>
            <div 
                className={`text-sm py-1 px-2 ${selected ? 'bg-pink text-white' : 'bg-yellow text-black'} absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-1/2 rounded-md shadow whitespace-no-wrap`}>
                {selected ? 'SELECTED' : 'ADD TO CART'}
            </div>
        </div>
    )
}

export default DeliveryOption
