import React, { useState, useEffect } from 'react'
import { FiLoader, FiShoppingCart, FiX } from 'react-icons/fi'
import Button from './UI/Button'

const Option = ({event, option, options, addProduct, removeProduct, current, setLoad}) => {
    const [loading, setLoading] = useState(false)
    
    const currency = option.locale.currency === 'GBP' ? '£' : '€' 
    const image = option.stripeCache.product.images.length > 0 ? option.stripeCache.product.images[0] : '/image4.png'
    const product = option
    const upcoming = event.stripeCache.upcoming
    const invoices = [...event.stripeCache.invoices, upcoming]
    const invoice = invoices.find(i => i && i.lines.data.find(l => l.price.product === option.stripeCache.product.id))
    const active = option.active === undefined ? invoice : option.active
    const price = (active && invoice) ? invoice.lines.data.find(l => l.price.product === option.stripeCache.product.id).amount : option.stripeCache.unit_amount

    useEffect(() => {
        if (!current?.loading) setLoading(false)
        setLoad(false)
    }, [current])

    const click = () => {
        if (upcoming && upcoming.loading) return
        if (!active && !current) {
            setLoading(true)
            setLoad(true)
        }
        if (active) removeProduct(product) 
        else addProduct(product)
    }

    return (
        <div
            className={`flex flex-col justify-start items-center h-84 w-11/12 lg:w-64 rounded-xl my-8 lg:my-0 shadow-lg cursor-pointer relative box-content z-50 ${active ? ' bg-white border-4 border-pink' : 'bg-white'} ${options >= 4 ? '' : 'mx-8'}`} 
            onClick={click}>
            {!loading &&
                <>
                    <div className={`text-base py-1 px-3 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full shadow whitespace-no-wrap bg-pink text-white ${loading ? 'flex items-center' : ''}`}>
                        {price === 0 ? 'OFFERED' : `+${currency}${price / 100}`}
                    </div>
                    <div className="w-11/12 lg:w-60 mt-4 lg:mt-2 bg-cover rounded-xl" style={{ backgroundImage: `url(${image})`, height: '11rem' }} />
                    <p className={`text-xl text-center mt-2`}>
                        {option.name}
                    </p>
                    <p className="text-base px-4 mt-1 text-center leading-tight opacity-80">
                        {option.content}
                    </p>
                    <Button Icon={active ? FiX : FiShoppingCart} variant="yellow" className="text-black left-1/2 bottom-0 transform -translate-x-1/2 translate-y-1/2 whitespace-no-wrap" style={{ position: 'absolute' }}>
                        {active ? 'REMOVE' : 'ADD TO CART'}
                    </Button>
                </>
            }
            {loading && <FiLoader className="h-8 w-8 stroke-1 rotating box-border inline mt-40" />}
        </div>
    )
}

export default Option
