import React, { useState } from 'react'
import I18n from '../components/UI/I18n'
import Title2 from './UI/Title2'
import Option from './Option'


const Options = ({ current, event, options, width, addProduct, removeProduct, setLoad }) => {
    const [selected, setSelected] = useState(options.map((option) => option.default))
    const style = width < 1024 ? 'w-3/12' : 'w-1/12'
    
    return (
        <React.Fragment>
            <div className="w-full sm:w-128 lg:w-full text-2xl my-10 mx-auto">
                <Title2 variant="medium" underline={`h-4/10 left-8 ${style}`}>
                    ➕&nbsp;<I18n>app-redesign.options.title</I18n> 
                </Title2>
            </div>
            <div className={`flex flex-col lg:flex-row ${options.length >= 4 ? 'justify-between' : 'justify-center'} items-center cursor-pointer`}>
                {event.stripeCache && options.sort((a, b) => b.name.localeCompare(a.name)).map((option, i) => (
                    // ------------ to kick out the delivery option of the options list uncomment the line below --------------------
                    // !option.shipping &&
                    <Option 
                        key={i}
                        current={current}
                        event={event}
                        option={option}
                        options={options.length}
                        addProduct={addProduct}
                        removeProduct={removeProduct}
                        selected={selected[i]} setLoad={setLoad}
                        onClick={() => setSelected(prevState => prevState.map((opt, index) => index === i ? !opt : opt))} />
                ))}
            </div>
        </React.Fragment>
    )
}

export default Options
