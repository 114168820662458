import React from 'react'

const Title2 = ({ children, className, variant = 'default', underline = 'w-full h-4/10 left-0' }) => {
    const styles = {
        small: 'text-base',
        default: 'text-xl',
        medium: 'text-2xl',
        large: 'text-3xl',
        'extra-large': 'text-4xl'
    }

    return (
        <p className={`${styles[variant]} font-medium capitalize relative z-10 ${className}`}>
            {children}
            <span className={`absolute rounded-2xl bottom-0 -z-10 bg-yellow ${underline}`}></span>
        </p>
    )
}

export default Title2
