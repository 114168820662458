import React, {useState, useEffect} from 'react'
import { DisableBodyScroll } from '../utils'
import I18n from './UI/I18n'
import Button from './UI/Button'
import Title2 from './UI/Title2'

const Customization = ({ customization = [], saved }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    const handler = event => {
    if (event.origin !== 'https://crea.wizito.com') return
    if (event.data?.event === 'saved') {
      setOpen(false)
      saved([event.data, customization[1]])
    }
    if (event.data?.event === 'exit')
      setOpen(false)
    }
    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [])
  const pola = (customization || [])[0]
  const screen = (customization || [])[1]

  return (
    <>
      <div className="flex flex-col justify-between text-center w-full md:w-96 h-96 p-5 md:ml-6 rounded-xl shadow-md text-white bg-pink flex-shrink-0">
        <Title2 variant="large" className="text-black" underline="w-9/12 h-4/10 left-1/10">
        🖌️ <I18n>app-redesign.customization.title</I18n>
        </Title2>
        <p className="text-base mx-auto my-3"><I18n>app-redesign.customization.description</I18n></p>
        <img src="/image3.png" alt="wedding" className="w-96 mx-auto" />
        <Button
          variant="yellow" 
          className="mx-auto mt-8 text-black" 
          // onClick={() => setOpen(true)}
          href={`https://crea.wizito.com/project/${pola.id}_${pola.token}?lng=en`}><I18n>app-redesign.customization.button</I18n></Button>
      </div>
      {open && pola &&
        <>
          <iframe src={`https://crea.wizito.com/project/${pola.id}_${pola.token}?lng=en`} className="fixed bg-white top-0 left-0 w-screen h-screen z-125" />
          <DisableBodyScroll />
        </>
      }
    </>
  )
}

export default Customization
