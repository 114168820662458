import React, { useState } from 'react'
import I18n from './UI/I18n'
import Button from './UI/Button'
import Title2 from './UI/Title2'
import DeliveryOption from './DeliveryOption'
import DeliveryModal from './DeliveryModal'
import { FiLoader } from 'react-icons/fi'

const Delivery = (props) => {
    const { shippings, event, upcoming, products, addProduct, removeProduct } = props
    
    const [selected, setSelected] = useState(event.products.some(p => p.upsale && p.shipping) ? [true, false] : [false, true])
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const inv = event.stripeCache.invoices
    const current = upcoming || inv.find(i => i.status === 'draft') || inv.find(i => i.status === 'open')
    const deliveryMethod = event.products.some(p => p.upsale && p.shipping) ? 'Delivery' : 'Pickup'
    
    return (
        <>
            <div className="flex flex-col justify-between items-center text-center w-full md:w-96 h-96 p-5 md:mx-6 rounded-xl shadow-md bg-white flex-shrink-0">
                <Title2 variant="large">
                🚛 <I18n>app-redesign.delivery.title</I18n>
                </Title2>
                <p className="text-base my-3"><I18n>app-redesign.delivery.description</I18n></p>
                <div className="flex justify-center items-center w-full mb-8 mt-2">
                    {shippings.sort((a,b) => a.upsale ? -1 : 1).map((shipping, i) => (
                        <DeliveryOption 
                            key={i} event={event} products={products} shipping={shipping} loading={loading} selected={selected[i]}
                            addProduct={addProduct} removeProduct={removeProduct} setLoading={setLoading} setSelected={setSelected} 
                            onClick={() => setSelected(selected.map(s => !s))} />
                    ))}
                </div>
                <Button 
                    variant="yellow"
                    onClick={() => setOpen(true)}>
                        {current?.loading || loading
                            ? <FiLoader className="h-5 w-5 stroke-1 rotating box-border inline" />
                            : `${deliveryMethod} information`
                        }
                </Button>
                <DeliveryModal {...props} open={open} setOpen={setOpen} isMarkerShown /> 
            </div> 
        </>
    )
}

export default Delivery